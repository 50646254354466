var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-1 ",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"py-1"},[_c('strong',[_vm._v(" "+_vm._s(_vm.banksQuestionList.name)+" ")]),_c('b-button',{staticClass:"ml-auto mr-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteBank(_vm.banksQuestionList.id)}}},[_vm._v("حذف البنك")]),_c('b-button',{attrs:{"variant":"outline-primary","to":"/banks"}},[_vm._v("رجوع")])],1)],1),_c('EKTableCollapse',{attrs:{"label":"label","rows":_vm.activeQuestionsList,"columns":_vm.header,"childId":"id","childrenLabel":"answers","customHeaderLabel":"label","no_delete":"","colapseHeader":_vm.questionHeader},on:{"details":_vm.details},scopedSlots:_vm._u([{key:"item-order",fn:function(ref){
var tr = ref.tr;
return [_c('b-button',{staticClass:"btn-icon rounded-pill",attrs:{"variant":"primary"}},[_vm._v(_vm._s(tr.order))])]}},{key:"sub-select-row",fn:function(ref){
var tr = ref.tr;
var val = ref.val;
return [_c('b-form-checkbox',{staticClass:"mx-auto d-inline-block",attrs:{"disabled":"","checked":tr.answers[val - 1].isCorrect}})]}},{key:"item-dateCreated",fn:function(ref){
var tr = ref.tr;
return [_vm._v(" "+_vm._s(new Date(tr.dateCreated).toLocaleDateString("en-GB"))+" ")]}}])}),_c('b-col',{staticClass:"d-flex justify-content-center mb-3",attrs:{"cols":"12"}},[_c('EKPagination',{attrs:{"items":_vm.searchedBanksQuestionList},model:{value:(_vm.activeQuestionsList),callback:function ($$v) {_vm.activeQuestionsList=$$v},expression:"activeQuestionsList"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }